import styled from "@emotion/styled";
import contact from "../../../../assets/images/contact.webp";

export const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 40px;

  border-radius: 8px;
  background-image: url(${contact});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.breakpoints.mobile} {
    gap: 20px;
    flex-direction: column;
  }

  .text {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
  }

  .button {
    padding: 16px 32px;
    font-size: 18px;
    color: #0f44a3;
    background-color: #fff;
    border-radius: 16px;
    transition: all 0.2s linear;

    .highlight {
      font-weight: bold;
    }

    &:hover {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.primary[500]};
    }
  }
`;
