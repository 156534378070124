import styled from '@emotion/styled';

const Wrapper = styled(`header`)`
	padding: 32px 0;
	margin-bottom: 40px;
	
	background-color: #131f63;
	color: #FFF;
	font-size: 28px;

	${({ theme }) => theme.breakpoints.tabletPortrait} {
		padding: 24px 0;
		margin-bottom: 32px;
		font-size: 24px;
	} 
`
interface Props {
	title: string;
}

export const Header = ({ title }: Props) => (
	<Wrapper>
		<h1 className="container mini">{title}</h1>
	</Wrapper>
)