import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin-top: 40px;
  border: 5px solid ${({ theme }) => theme.colors.primary[300]};
  width: 100%;
  border-radius: 8px;
  padding: 32px 40px;
  transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    transform: scale(1.025);
  }

  .title {
    text-align: center;
    margin-bottom: 32px;
  }
  .stars {
    display: flex;
    margin: 40px 0;
    justify-content: center;
  }

  .description {
    font-size: 18px;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.typography.fonts.heading};
    font-style: italic;
    color: hsl(191deg 19% 33%);
  }

  .author {
    margin-top: 32px;
    font-size: 16px;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.typography.fonts.heading};
    font-style: italic;
    color: hsl(191deg 19% 33%);
    text-align: right;
  }
`;
