import React from "react";
import { PageSlices } from "../../types";
import { SliceBody } from "./SliceBody";
import {
  SliceIllustration,
  SliceImageAndText,
  SliceContactEntretien,
} from "./";
import styled from "@emotion/styled";

interface Props {
  pageSlices: PageSlices;
}

const SliceContainer = ({ pageSlices }: Props) => {
  const slices = pageSlices
    .filter((slice) => !slice?.disabled)
    .map((slice) => {
      let el = null;
      switch (slice?._type) {
        case `bodySection`:
          el = <SliceBody key={slice._key} slice={slice} />;
          break;
        case `illustration`:
          el = <SliceIllustration key={slice._key} slice={slice} />;
          break;
        case `imageAndText`:
          el = <SliceImageAndText key={slice._key} slice={slice} />;
          break;
        case `contactEntretien`:
          el = <SliceContactEntretien key={slice._key} slice={slice} />;
          break;
        default:
          el = <></>;
      }
      return el;
    });
  return <Wrapper className="wrapper-slices">{slices}</Wrapper>;
};

const Wrapper = styled.div`
  strong,
  a {
    color: #009fe2;
  }

  a {
    text-decoration: underline;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.primary[500]};
    font-size: 24px;
    margin: 24px 0 16px 0;
  }

  p,
  ul {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.225;
  }

  li {
    display: flex;
    gap: 4px;
    align-items: baseline;
  }

  li::before {
    content: "• ";
    font-size: 28px;
    color: #009fe2;
    position: relative;
    top: 7px;
  }

  .col div h3:first-child {
    margin-top: 0;
  }
`;

export default SliceContainer;
