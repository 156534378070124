import React from "react";
import LayoutMain from "../../../../components/LayoutMain";
import { graphql, PageProps } from "gatsby";
import { Header } from "../Header";
import { Page } from "../../types";
import SliceContainer from "../slices/SliceContainer";
import MainImage from "../MainImage";
import ContactButton from "../ContactButton";
import { Wrapper } from "./style";
import SeoHelmet from "../../../seo/components/SeoHelmet";
import getGraphBase from "../../../../helpers/getGraphBase";
import SectionAvis from "../SectionAvis";
import GoogleMap from "../../../../components/GoogleMap";

interface Props extends PageProps {
  data: Page;
}

const TemplatePage = ({ data }: Props) => {
  const { title, slug, seoTitle, seoDescription, seoImage } = data.sitePage;
  const alt = data.sitePage.mainImage?.alt || "";
  const mainImage = data.sitePage?.mainImage?.asset;
  const canonical = `https://energiepiscines.com/${slug.current}`;
  const schema = getGraphBase();

  return (
    <>
      <SeoHelmet
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoImageUrl={seoImage?.asset?.url}
        canonical={canonical}
        schema={schema}
      />
      <LayoutMain logo={data.sanitySiteSettings.logo.asset}>
        <Header title={title} />
        <Wrapper as="section">
          {mainImage && (
            <div className="container mini">
              <MainImage mainImage={mainImage} alt={alt} />
            </div>
          )}
          <SliceContainer pageSlices={data.sitePage.content} />
          {data.sitePage.avisSection && (
            <SectionAvis
              avisSection={data.sitePage.avisSection}
              titleAvis={data.sitePage.titleAvis}
            />
          )}
          <GoogleMap />

          <div className="container mini">
            <ContactButton />
          </div>
        </Wrapper>
      </LayoutMain>
    </>
  );
};

export const query = graphql`
  query ($pageSlug: String!) {
    sanitySiteSettings {
      title
      logo {
        asset {
          gatsbyImageData(
            width: 267
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    sitePage: sanityPage(slug: { current: { eq: $pageSlug } }) {
      title
      seoTitle
      seoDescription
      seoImage {
        asset {
          url
        }
      }
      content {
        ... on SanityBodySection {
          _type
          _key
          disabled
          _rawBodyTextt(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityIllustration {
          _key
          _type
          disabled
          image {
            alt
            asset {
              gatsbyImageData(layout: FULL_WIDTH, formats: AVIF)
            }
          }
        }
        ... on SanityImageAndText {
          _key
          _type
          disabled
          imagePosition
          label
          _rawText(resolveReferences: { maxDepth: 10 })
          mainImage {
            alt
            asset {
              gatsbyImageData
            }
          }
        }
        ... on SanityImageAndText {
          _key
          _type
          disabled
          imagePosition
          label
          _rawText(resolveReferences: { maxDepth: 10 })
          mainImage {
            alt
            asset {
              gatsbyImageData
            }
          }
        }
        ... on SanityContactEntretien {
          _key
          _type
          disabled
          title
          backgroundImage {
            alt
            asset {
              gatsbyImageData
            }
          }
        }
      }
      titleAvis
      avisSection {
        _key
        note
        nom
        message
        pointFort
      }
      slug {
        current
      }
      mainImage {
        alt
        asset {
          gatsbyImageData
        }
      }
    }
  }
`;

export default TemplatePage;
