import { Wrapper } from './style'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'

interface Props {
	mainImage: IGatsbyImageData;
	alt: string;
}

const MainImage: FC<Props> = ({ mainImage, alt }: Props) => {
	const mainGatsby = mainImage && getImage(mainImage)

	if (!mainGatsby) {
		return <></>
	}

	return (
		<Wrapper>
			<GatsbyImage image={mainGatsby} alt={alt} />
		</Wrapper>
	)
}

export default MainImage