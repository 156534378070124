import PortableText from "../../../../components/Sanity/PortableText";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ISliceImageAndText } from "../../types/index";

const Wrapper = styled(`div`)`
  position: relative;
  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 40px auto;

  ${({ theme }) => theme.breakpoints.tabletPortrait} {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "left"
      "right";

    margin: 0;
  }

  .col {
    > div > .title {
      margin-top: 0;
    }
    &.left {
      grid-area: left;
    }
    &.right {
      grid-area: right;
    }

    &.wrapper-image {
      display: flex;
      ${({ theme }) => theme.breakpoints.tabletPortrait} {
        grid-area: left;
      }
      ${({ theme }) => theme.breakpoints.mobile} {
        display: flex;
        justify-content: center;
      }
    }

    &.wrapper-text {
      ${({ theme }) => theme.breakpoints.tabletPortrait} {
        grid-area: right;
      }
    }
  }
`;

interface Props {
  slice: ISliceImageAndText;
}

export const SliceImageAndText = ({ slice }: Props) => {
  const image = getImage(slice.mainImage.asset);

  if (image) {
    return (
      <Wrapper className="container mini slice-image-text">
        <div
          className={`wrapper-image col ${
            slice.imagePosition === "left" ? "left" : "right"
          }`}
        >
          <GatsbyImage image={image} alt={slice.label} />
        </div>

        <div
          className={`wrapper-text col ${
            slice.imagePosition === "left" ? "right" : "left"
          }`}
        >
          <PortableText blocks={slice._rawText} />
        </div>
      </Wrapper>
    );
  }
  return <></>;
};
