import styled from "@emotion/styled";

export const Wrapper = styled(`section`)`
  position: relative;
  height: 450px;
  iframe {
    position: absolute;
    height: 450px;
  }
`;
