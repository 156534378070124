
import styled from "@emotion/styled"
import PortableText from "../../../../components/Sanity/PortableText"
import { ISliceBody } from "../../types/index"

interface Props {
	slice: ISliceBody
}

const Wrapper = styled.div`

`

export const SliceBody = ({ slice }: Props) => {
	return <Wrapper className="slice-body container mini"><PortableText blocks={slice._rawBodyTextt} /></Wrapper>
}