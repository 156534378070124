import styled from "@emotion/styled";
import { Link } from "gatsby";

export const Button = styled.button`
  display: inline-block;
  position: relative;
  margin-top: 16px;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  border: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #fff !important;
  background-color: hsl(342deg 100% 38%);
  border: 1px solid red;
  cursor: pointer;
  transition: all 0.25s linear;

  &.light {
    background-color: hsl(342deg 70% 57%);
    border: 1px solid #ffaeba00;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 16px;
    padding: 10px 14px;
  }

  &:hover {
    background-color: hsl(342deg 78% 50%);
  }
`;

export const ButtonKnowMore = styled(Link)`
  padding: 14px 16px;

  color: #fff;
  background-color: ${({ theme }) => theme.colors.primary[500]};

  font-size: 16px;
  font-weight: 100;

  border-radius: 4px;
  border: none;
  transition: all 0.2s linear;
  cursor: pointer;

  a,
  &:visited {
    color: #fff;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[600]};
  }
`;
