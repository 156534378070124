import styled from "@emotion/styled";
import Temoignages from "../../../../components/Temoignages";
import { AvisSection } from "../../types";

interface Props {
  titleAvis?: string;
  avisSection: AvisSection[];
}

export default function SectionAvis({ titleAvis, avisSection }: Props) {
  return (
    <section className="container big">
      <Title>{titleAvis}</Title>
      <Wrapper>
        {avisSection.map((avis) => (
          <Temoignages
            title={avis.pointFort}
            description={avis.message}
            author={avis.nom}
          />
        ))}
      </Wrapper>
    </section>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    flex-direction: column;
  } ;
`;

const Title = styled.div`
  font-size: clamp(25px, 3vw, 45px);
  line-height: 1.1;
  font-family: ${({ theme }) => theme.typography.fonts.heading};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary[300]};
`;
