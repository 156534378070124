import React from "react";
import { Wrapper } from "./style";

const ContactButton = () => {
  return (
    <Wrapper>
      <p className="text">Du lundi au vendredi</p>

      <a
        data-gtag-phonecall="phone-call"
        className="button"
        href="tel:+33987000464"
      >
        J'appelle le <span className="highlight">09 87 00 04 64</span>
      </a>
    </Wrapper>
  );
};

export default ContactButton;
