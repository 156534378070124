import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ISliceIllustration } from "../../types/index";

const Wrapper = styled(`div`)`
  position: relative;
`;

interface Props {
  slice: ISliceIllustration;
}

export const SliceIllustration = ({ slice }: Props) => {
  const image = getImage(slice.image.asset);

  if (image) {
    return (
      <Wrapper className="container mini slice-image">
        <GatsbyImage image={image} alt={slice.image.alt} />
      </Wrapper>
    );
  }
  return <></>;
};
