import { Wrapper } from "./style";

const GoogleMap = () => {
  return (
    <Wrapper className="google-map">
      <iframe
        width="100%"
        height="450"
        style={{ border: 0, height: 450 }}
        loading="lazy"
        allowFullScreen
        title="Energie Piscine Vence"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDmgifyO5dhM1Gw9fiPK7yUb6rkvFzy8ME&q=Energie+Piscines,Vence+WA&center=43.71885920825648, 7.114000306426816`}
      ></iframe>
    </Wrapper>
  );
};

export default GoogleMap;
