import PortableText from "../../../../components/Sanity/PortableText";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ISliceContactEntretien } from "../../types/index";
import shape from "../../../../assets/images/banner-one-bottom-shape.png";
import { Button } from "../../../../styles/components/buttons";
import { Link } from "gatsby";

const Wrapper = styled(`div`)`
  position: relative;
  gap: 16px;
  margin: 40px auto;
  max-width: 1920px;
  min-height: 550px;

  .gatsby-image-wrapper {
    min-height: 550px;
  }

  &:before  {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #263b45b0;
    z-index: 10;
  }

  .content {
    position: absolute;
    z-index: 20;
    top: 40%;

    width: 100%;
    max-width: 940px;
    transform: translate(-50%, -50%);
    left: 50%;

    .cta {
      margin: 40px auto 0;
      display: block;
    }
    a {
      color: #fff;
      text-decoration: none;
    }

    .title {
      color: #fff;
      font-size: clamp(25px, 4vw, 45px);
      text-align: center;
    }
    .desc {
      margin-top: 40px;
      color: #fff;
      font-size: 22px;
      text-align: center;
    }
  }

  .shape {
    position: absolute;
    z-index: 20;
    max-width: 100%;

    &.bottom {
      bottom: 0;
    }
    &.top {
      top: 0;
      transform: rotate(180deg);
    }
  }
`;

interface Props {
  slice: ISliceContactEntretien;
}

export const SliceContactEntretien = ({ slice }: Props) => {
  const image = getImage(slice.backgroundImage.asset);

  if (image) {
    return (
      <Wrapper className=" slice-contact-entretien">
        <div className="content">
          <h2 className="title">{slice.title}</h2>
          <p className="desc">
            Energie Piscines, c'est plus de 10 ans d'expériences et des
            centaines de clients satisfait. <br /> Nous intervenons à{" "}
            <Link to="/entretien-piscine-vence/">Vence</Link>,{` `}
            <Link to="/entretien-de-piscine-a-tourrettes-sur-loup-par-un-professionnel">
              Tourrettes-sur-Loup
            </Link>
            ,{` `}
            <Link to="/la-colle-sur-loup-entretien-piscine">
              La Colle-sur-Loup
            </Link>
            , Saint-Jeannet,{" "}
            <Link to="/entretien-piscine-saint-paul-de-vence">
              Saint-Paul de Vence
            </Link>
            ...
            <br />
          </p>
          <Link to="/contact-pisciniste-vence/">
            <Button className="cta">Je demande un devis gratuit</Button>
          </Link>
        </div>
        <img className="shape bottom" src={shape} />
        <img className="shape top" src={shape} />
        <GatsbyImage
          objectFit="cover"
          imgStyle={{
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
          image={image}
          alt={slice.backgroundImage.alt}
        />
      </Wrapper>
    );
  }
  return <></>;
};
