import React from "react";
import { Wrapper } from "./style";
import { RiStarSFill } from "react-icons/ri";

interface Props {
  title?: string;
  description?: string;
  author?: string;
}

const Temoignages = ({ title, description, author }: Props) => {
  return (
    <Wrapper className="temoignages">
      <h3 className="title">
        {!title
          ? "Ponctualité, Professionnalisme, Rapport qualité-prix, Réactivité"
          : title}
      </h3>
      <div className="stars">
        <RiStarSFill aria-label="etoile" color="ffbb00" size={32} />
        <RiStarSFill aria-label="etoile" color="ffbb00" size={32} />
        <RiStarSFill aria-label="etoile" color="ffbb00" size={32} />
        <RiStarSFill aria-label="etoile" color="ffbb00" size={32} />
        <RiStarSFill aria-label="etoile" color="ffbb00" size={32} />
      </div>
      <div className="description">
        {description || (
          <React.Fragment>
            {" "}
            <p>Grande disponibilité, interventions toujours efficaces.</p>
            <p>
              Il existe peu de professionnels auxquels j'accorde une telle
              confiance. Tarifs tout à fait raisonnables, plutôt rares dans ce
              secteur.
            </p>
            <p>A la boutique Amelie réserve toujours le meilleur accueil.</p>
            <p>Une vraie équipe !!!</p>
            <p> A recommander sans réserve.</p>
          </React.Fragment>
        )}
      </div>
      <p className="author">{author || "Claudine Bagaria"}</p>
    </Wrapper>
  );
};

export default Temoignages;
